import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import _get from 'lodash/get';
import { GET_CART } from './../index';

// Utils
import { sendGoogleAnalyticsEvent } from 'utils/GoogleAnalytics';
import { SiteContext } from 'utils/SiteProvider';
import { useMiniCart } from 'operations/queries/getMiniCart';

export const PLACE_ORDER = gql`
  mutation PLACE_ORDER {
    completeCart {
      code
      success
      message
      splitOrders {
        id
        orderNumber
        salesRepId
        requestedDeliveryDate
        salesRep {
          id
          code
          name
          defaultPhone {
            id
            phoneNumber
          }
          defaultEmail {
            id
            email
          }
          company {
            id
            code
            name
          }
        }
      }
    }
  }
`;

const analyticsPurchase = (currency: string, cart: any) => {
  sendGoogleAnalyticsEvent('purchase', {
    currency,
    transaction_id: cart.id,
    tax: cart.taxTotal,
    value: cart.total,
    items: cart.lines?.map((obj: any) => {
      return {
        item_id: obj.productId,
        item_name: obj.product?.name,
        quantity: obj.quantity,
        price: obj.price,
      };
    }),
  });
};

export const usePlaceOrder = () => {
  const site: any = useContext(SiteContext);

  const { data: miniCart } = useMiniCart();

  const [mutate, { data, error }] = useMutation<any>(PLACE_ORDER, {
    update: (cache, { data }) => {
      analyticsPurchase(site?.settings?.currency, miniCart?.cart);

      const completedCartId = _get<string | null>(
        data,
        'completeCart.completedCartId',
        null
      );

      if (completedCartId) {
        // We need to clear the cart by removing it from the cache
        cache.writeQuery({
          query: GET_CART,
          data: {
            cart: {},
          },
        });

        // Evict the cart object from the cache
        cache.evict({
          id: `Cart:${completedCartId}`,
        });
        // At this point we will end up with the dangling object in cache
        // which will be the cleared cart. i.e. cart:<cleared_cart_id>
        // Executing garbage collection will remove the dangling object.
        cache.gc();
      } else {
        console.log(`Expected completed cart id, got: ${completedCartId}`);
      }
    },
    refetchQueries: [{ query: GET_CART }],
  });

  const customMutate = () => {
    return new Promise((resolve, reject) => {
      mutate()
        .then((response) => {
          resolve(_get(response, 'data.completeCart', {}));
        })
        .catch(reject);
    });
  };

  return { mutate: customMutate, data, error };
};
